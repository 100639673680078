$padding-top-bottom: 10px;
$mobile: 640px;

$header-height: 40px;
$header-padding: $padding-top-bottom 15px $padding-top-bottom 15px;
$header-zIndex: 99;

$spacing: 5px;

.mt-4 {
    margin-top: 1.5rem;
}
