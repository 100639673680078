@import "../../assets/styles/shared-styles.scss";

$chevron-width: 5%;
$p: 10px;

.media-carousel {
    position: fixed;
    display: flex;
    flex-flow: column;
    align-items: center;
    left: 0;
    width: 100%;
    background: #ffffff;
    z-index: calc(#{$header-zIndex} + 2);
    padding: $p 0;
    -webkit-box-shadow: 0px 2px 12px -1px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 2px 12px -1px rgba(0, 0, 0, 0.7);

    &__navigator {
        display: flex;
        align-items: center;
        width: 100%;
        flex: 1;
        overflow: hidden;

        &__media {
            flex: 1;
            height: 100%;
        }

        &__chevron {
            display: flex;
            justify-content: center;
        }

    }
    
    &__footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 2%;
        padding-right: 5%;
        width: 100%;
    }
}

.carousel-dim {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: calc(#{$header-zIndex} + 1);
}

@media screen and (orientation: portrait) {
    .media-carousel {
        top: 50%;
        height: 50vh;
        transform: translateY(-50%);
    }
}
@media screen and (orientation: landscape) {
    .media-carousel {
        top: 0;
        bottom: 0;
        height: calc(100vh - #{$p} * 2);

        .chevron {
            min-width: 10%;
        }
    }
}
