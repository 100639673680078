@import "src/assets/styles/shared-styles.scss";

$main-font-color: #4f5255;
$stronger-font: 600;

.mission {
    display: flex;
    flex-flow: column;
    width: 90%;
    margin: 3vh auto 0 auto;
    padding-bottom: 70px;

    color: $main-font-color;

    @media screen and (min-width: $mobile) {
        width: 70%;
    }

    .mission-section {
        display: flex;
        width: 92%;
        margin: 15px auto;

        .section-title {
            width: 40%;
        }
        .section-content {
            flex: 1;
            padding-left: 10px;

            .za-list {
                $spacing: 5px;

                display: flex;
                flex-flow: row wrap;

                .za-item {
                    position: relative;
                    display: flex;
                    border: solid 1px rgb(180, 180, 180);
                    font-size: 0.8rem;
                    min-height: 25px;
                    border-radius: 2px;
                    margin: 0 $spacing $spacing 0;
                    padding: 3px 5px;
                    align-items: center;

                    a {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .history {
            display: flex;
            flex-flow: column;
            width: 100%;

            &__item {
                display: flex;
                flex-flow: row wrap;
                margin: 15px 0;

                &:first-of-type {
                    margin-top: 0;
                }

                &__info {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    @media screen and (min-width: $mobile) {
                        width: 60%;
                    }

                    .deco {
                        align-self: flex-start;
                        width: 20px;
                        height: 7px;
                        margin-top: 7px;
                        margin-right: 10px;
                        background-color: #fff200;
                    }

                    &__author {
                        flex: 1;
                        line-height: 23px;

                        &__name {
                            font-weight: $stronger-font;
                            margin-right: 3px;
                        }

                        &__time {
                            font-size: 14px;
                            color: rgb(146, 146, 146);
                        }
                    }
                }

                &__action {
                    display: flex;
                    width: 100%;
                    margin-top: 7px;

                    @media screen and (min-width: $mobile) {
                        flex: 1;
                        margin-top: 0;
                    }

                    &__label {
                        font-weight: $stronger-font;
                    }

                    &__reason {
                        flex: 1;
                    }
                }

                &__comment {
                    color: rgb(114, 114, 114);
                    padding: 8px 0 8px 10px;
                    font-style: italic;
                    font-size: 15px;
                }

                &__assignment {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    width: 100%;
                    margin-top: 7px;

                    &__agent {
                        display: flex;
                        margin-right: 15px;
                        
                        @media screen and (max-width: $mobile) {
                            width: 100%;
                            margin-right: 0;
                        }

                        &__label {
                            font-weight: $stronger-font;
                        }
                        &__name {
                            flex: 1;
                        }
                    }
                }
            }

            &__item-separator {
                border-top: solid 1px rgb(182, 182, 182);
                margin: auto;
                width: 95%;

                &:last-of-type {
                    display: none;
                }
            }
        }
    }

    .mission-comment-button {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 60px;
    }
}

.title-line-across {
    font-size: 16px;
    border-bottom: solid 2px #b9b9b9;
    padding: 0 0 0.25rem 0.5rem;
    font-weight: bold;
}
