.custom-button {
    border: none;
    padding: 10px 20px;
    cursor:pointer;

    &--grey {
        background: gainsboro;
    }
    
    &--yellow {
        background: #ffed00;
    }
}
