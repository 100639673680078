@import "../../assets/styles/shared-styles.scss";

$p: 15px;
$close-icon-width: 50px;

.modal {
    color: rgb(51, 51, 51);
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 20%;
    width: 80%;
    padding: 0 $p;
    background: #ffffff;
    z-index: calc(#{$header-zIndex} - 1);
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 5px;

    @media screen and (min-width: $mobile) {
        width: 400px;
    }

    &__header {
        position: relative;
        padding: $p;
        width: 100%;
        border-bottom: 1px solid #dee2e6;
        font-size: 1.2rem;
        min-height: 24px;

        &__text {
            margin-right: calc(#{$close-icon-width} - #{$p});
        }

        &__close-icon {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-end;
            width: 50px;
            height: 100%;

            svg {
                fill: rgb(80, 80, 80);
            }
        }
    }

    &__text {
        display: flex;
        padding: $p;
        width: 100%;
        border-bottom: 1px solid #dee2e6;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 10px $p;

        * {
            margin-right: 10px;
        }

        *:last-child {
            margin-right: 0;
        }
    }

    &__comment {
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: $p;
        border-bottom: 1px solid #dee2e6;

        &__title {
            padding-left: 5px;
            font-size: 15px;
            margin-bottom: 8px;
        }

        textarea {
            // width: 100%;
            min-height: 145px;
            border: solid 1px #aaaaaa;
            border-radius: 3px;
            resize: vertical;
            padding: 7px;
        }
    }
    
}

.modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
