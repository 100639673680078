@import "src/assets/styles/shared-styles.scss";

$yellow-background: #ffed00;

.dashboard {
    display: flex;
    width: 80%;
    flex-direction: column;
    padding: 0 10% 100px;

    @media screen and (max-width: $mobile) {
        width: 100%;
        padding: 0 0 100px;
    }

    .list {
        margin-bottom: 40px;

        .list-title-container {
            display: flex;
            flex-direction: row;
            align-self: center;

            @media screen and (max-width: $mobile) {
                flex-direction: column;
                margin-bottom: 10px;
            }

            button {
                display: flex;
                align-self: center;
                height: 40px;
                font-size: 12px;
                border: none;
                background-color: $yellow-background;
                padding: 0 10px;
                cursor: pointer;
                align-items: center;

                &[disabled] {
                    background-color: lightgray;
                    cursor: default;
                }

                @media screen and (max-width: $mobile) {
                    height: 25px;
                    font-size: 12px;
                }
            }

            .list-title {
                text-transform: uppercase;
                color: #000;
                line-height: 35px;
                letter-spacing: 0;
                font-size: 30px;
                font-weight: 400;
                margin: 20px 0;
                width: 100%;
                align-self: center;

                @media screen and (max-width: $mobile) {
                    font-size: 15px;
                    font-weight: 600;
                    margin: 10px 0 0 0;
                    width: auto;
                }
            }
        }

        .list-content {
            display: flex;
            flex-direction: column;
            align-self: center;
            width: 100%;

            .list-header {
                font-weight: bold;
                display: flex;
                background-color: #000;
                color: #ffffff;
                padding: 15px 0px;
                height: 30px;
                text-align: center;

                &--mission {
                    padding: 20px 0px;
                }

                .list-header-tab {
                    display: flex;
                    background-color: #000;
                    font-size: 13px;
                    line-height: 18px;
                    text-transform: uppercase;
                    border-right: 1px solid #ffffff;
                    align-items: center;
                    justify-content: center;

                    svg {
                        margin-left: 10px;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:last-of-type {
                        border: none;
                    }

                    @media screen and (max-width: $mobile) {
                        flex-direction: column;

                        svg {
                            margin-left: 0;
                        }
                    }
                }
            }

            .list-item {
                display: flex;
                align-items: center;
                min-height: 57px;

                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 50px;
                }

                a {
                    width: 100%;
                }

                &:hover,
                &:nth-child(2n):hover {
                    background-color: rgba(0, 0, 0, 0.2);
                }

                &:nth-child(2n) {
                    background-color: rgba(0, 0, 0, 0.05);
                }

                .clickable-area {
                    color: #000000;
                    text-decoration: none;
                    background-color: $yellow-background;
                    border: 1px solid #ffffff;
                    cursor: pointer;
                }

                .city-column {
                    text-align: center;
                }

                .context-column {
                    &-percentage {
                        white-space: nowrap;
                        width: 55%;
                    }

                    &-icon {
                        width: 45%;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        color: #000000;
                    }

                    a {
                        color: #000000;
                        text-decoration: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .za-column.mission-za-list {
                    $spacing: 5px;

                    display: flex;
                    justify-content: center;
                    flex-flow: row wrap;
                    padding-top: $spacing;

                    .mission-za-list-item {
                        display: flex;
                        border: solid 1px rgb(180, 180, 180);
                        font-size: 0.6rem;
                        min-height: 25px;
                        border-radius: 2px;
                        margin: 0 calc(#{$spacing} / 2) $spacing;
                        padding: 3px 5px;
                        align-items: center;
                    }
                }

                .action-column {
                    display: flex;
                    justify-content: space-around;

                    .action {
                        position: relative;
                        width: 45%;

                        a {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                        }

                        svg {
                            width: auto;
                            height: 30px;
                        }
                    }
                }
            }

            .no-mission {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 0 30px;
                font-size: 1.5em;
                margin-top: 9vh;
            }
        }
    }

    .not-clickable {
        pointer-events: none;
    }
}

/* COLUMN WIDTHS -- CANNOT BE NESTED AS THEY ARE USED BY DIFFERENT PARENT DIVS */

// ZA LIST
.index-column {
    width: 18%;
}

.city-column {
    width: 30%;
}

.road-column {
    width: 30%;
}

.context-column {
    width: 22%;
}

// MISSION LIST
.id-column {
    width: 20%;
}

.za-column {
    width: 50%;
}

.action-column {
    width: 30%;
}
/* COLUMN WIDTHS - END */

svg.return-to-top--hidden {
    display: none;
}

svg.return-to-top--show {
    position: fixed;
    bottom: 50px;
    right: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: #282c34;
    border-radius: 35px;
    fill: #ffffff;
    cursor: pointer;
}
