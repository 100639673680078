.anais-cgu-terms-consent {
    background-color: #FFFFFF;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    overflow-y: auto;
    padding: 30px;

    &__languages {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        text-transform: uppercase;

        &__lang {
            display: flex;
            color: #FFFFFF;
            justify-content: center;
            align-items: center;
            background: #000000;
            padding: 5px;
            margin-left: 2px;

            &--dimmed {
                opacity: 0.3;
                cursor: pointer;
            }
        }
    }

    &__content {
        display: flex;
        flex-flow: column;
        min-height: 100%;

        &__text {
            text-align: justify;
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-around;
        margin: 30px 0 20px 0;
        width: 100%;

        button {
            border: none;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &__disconnect {
            padding-left: 20px;
            padding-right: 20px;
            background: gainsboro;
        }

        &__accept {
            padding-left: 30px;
            padding-right: 30px;
            background: #ffed00;
        }
    }
}

.roboto-gras {
    font-family: "Roboto-Bold", verdana, sans-serif;
}
