.Container_category {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0px 30px 30px 30px;
}

.Container_category a {
    text-decoration: none;
    width: 100%;
}

.Container_category-title {
    font-size: 20px;
    padding: 20px 20px 0 20px;
    color: #000000;
    display: flex;
    align-items: center;
}

.Container_category-title svg {
    width: 20px;
    height: 20px;
    color: #000000;
    margin-left: 10px;
}

.category_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.Category-content_bloc {
    display: flex;
    flex-direction: column;
    width: 35%;
    min-height: 1px;
    text-align: center;
    border: 1px solid #000000;
    margin-top: 15px;
    background-color: #f2f2f2;
    position: relative;
}



.Category-content_bloc img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Category-content_bloc p {
    font-size: 14px;
    padding: 5px;
    font-style: italic;
    color: #a5a5a5;
    word-break: break-word;
}
.not-undefined strong {
    color: #000000;
    font-style: normal;
}

@media screen and (max-width: 640px) {
    .Container_category {
        padding: 15px;
    }

    .Container_category-title {
        margin: 10px;
        padding: 0;
        flex-direction: row;
        justify-content: center;
    }

    .Category-content_bloc {
        width: 100%;
    }
}
