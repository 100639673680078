@import 'src/assets/styles/shared-styles.scss';

.App-header {
  background-color: #282c34;
  height: $header-height;
  display: flex;
  padding: $header-padding;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 6px grey;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: $header-zIndex;
}

.App-header_logo img {
  height: 35px;
  display: flex;
  width: 75px;
  align-self: left;
}

.App-header_Usercompte {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 10px;
  color: #ffffff;
}

.App-header_Usercompte svg {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.App-header_User {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.App-header_lang {
  cursor: pointer;
  border: none;
  background-color: #ffed00;
  min-width: 30px;
  text-align: center;
  text-align-last: center;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  /* text-indent: 4px; */
  text-overflow: "";
}

option {
  text-align: center;
}

.account-link {
  display: flex;
  color: unset;
  text-decoration: none;
}
