.pwa-install-button {
  // display is set to "none" in inline style. It is set to flex when button needs to be visible
    z-index: 20000;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 10vh;
    background: white;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    font-size: 15px;
  
    img {
      width: auto;
      height: 50%;
      margin: 0 15px;
      padding: 5px;
    }
  
    .remove-prompt {
      margin: 3px 10px 0 auto;
    }
  }
  