.Container_category-edition {
    margin: 10px;
    padding: 0px 30px 30px 30px;
    // display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    -webkit-appearance: none;
    border-radius: 0;
}

.Category-content_bloc-edition input[type="button"] {
    font-size: 14px;
    padding: 5px;
    color: #a5a5a5;
    height: 100%;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    width: 100%;
}

.Category-content_bloc-edition input[type="button"]:focus {
    font-size: 14px;
    padding: 5px;
    color: #ffffff;
    height: 100%;
    background-color: #a5a5a5;
    border: none;
    cursor: pointer;
    width: 100%;
}

.Category-content_bloc-edition.active {
    width: 30%;
    flex-direction: column;
    border: 1px solid #000000;
    margin-top: 15px;
    display: flex;
    background-color: #a5a5a5;
    align-items: center;
    text-align: center;
    height: 50px;
}

.Category-content_bloc-edition.active input[type="button"] {
    background-color: transparent;
    color: #ffffff;
}

.Category-content_bloc-edition input[type="input"],
.Category-content_bloc-edition input[type="text"] {
    font-size: 14px;
    color: #a5a5a5;
    height: 100%;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    width: 99%;
    text-align: center;
}

.Category-content_bloc-edition input[type="file"] {
    font-size: 14px;
    color: #a5a5a5;
    height: 100%;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.Category-content_bloc-edition {
    width: 30%;
    flex-direction: column;
    border: 1px solid #000000;
    margin-top: 15px;
    display: flex;
    background-color: #ffffff;
    align-items: center;
    text-align: center;
    height: 50px;
}

.Title-context {
    font-size: 20px;
    margin-bottom: 10px;
}

button#save {
    border: none;
    display: flex;
    align-self: center;
    height: 50px;
    width: 250px;
    padding: 5px;
    margin-top: 10px;
    justify-content: center;
    background-color: #ffed00;
    color: #000000;
    font-weight: bold;
    font-size: 18px;
    align-items: center;
}

.save-context {
    position: relative;

    button#save:disabled {
        background-color: lightgray;
    }

    &__sync-indicator {
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media screen and (max-width: 640px) {
    .Category-content_bloc-edition,
    .Category-content_bloc-edition.active {
        width: 100%;
    }

    .Title-context {
        text-align: center;
        padding: 10px;
        display: flex;
        flex-direction: column;
    }

    button#save {
        width: 100%;
        background-color: #ffed00;
        margin: 0;
    }

    #snackError {
        width: 99%;
    }
}
