
.bloc-photo {
    position: relative;
    max-height: 250px;
    min-height: 150px;
}

.remove-icon {
    position: absolute;
    top: 0p;
    right: 0;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
        padding: 5px;
    }
}

img.play-button {
    position: absolute;
    width: auto;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

    
@media screen and (max-width: 640px) {
    .Category-content_bloc {
        width: 45%;
    }
    
    .bloc-photo {
        height: 120px;
        min-height: auto;
    }
  }


  