.loader-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

}