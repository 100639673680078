.ListContext_main-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.ListContext_container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 80%;
    align-self: center;
}

.content_container {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
    scroll-margin-top: 60px;
}

.content_container:nth-child(2n) {
    background-color: #ffffff;
    border: 1px solid #f2f2f2;
}

svg.arrow-back {
    position: fixed;
    top: 30%;
    left: 20px;
    width: 30px;
    height: 30px;
    padding: 5px;
    display: flex;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
    fill: #ffffff;
    background-color: #282c34;
    border-radius: 25px;
    cursor: pointer;
    z-index: 2;
}

.ListContext-block_infos {
    display: flex;
    height: 40px;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.ListContext-block_infos div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-right: 1px solid #e1e1e1;
    flex: 1;
}

.ListContext-block_infos div svg {
    fill: #ffed00;
}

.ListContext-block_infos p {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px 0 5px;
    font-size: 12px;
    flex: 3;
}

.ListContext-block_image {
    display: flex;
    justify-content: center;
}

.ListContext-block_image img {
    width: auto;
    height: auto;
}

@media screen and (max-width: 640px) {
    .Listcontext_content-container {
        flex-wrap: nowrap;
        width: auto;
        padding: 15px;
    }
    .ListContext_container {
        width: 100%;
        padding: 0;
    }
    .MuiTabs-flexContainer-11 button.MuiButtonBase-root-32 {
        min-height: 40px;
        max-width: 264px;
        min-width: 72px;
    }
    .ListContext-block_image img {
        width: 100%;
        height: auto;
    }
}

.preFilling-button {
    background-color: #ffed00;
    border: none;
    height: 30px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
}

/******* NAV *******/

button.ContextTab {
    min-height: 40px;
    max-width: 130px;
}

button.ContextTabsScrollButtons {
    display: flex;
    max-width: 40px;
    min-width: 30px;
    background: #ffed00;
}

button.ContextTab[aria-selected="true"] {
    background-color: #ffed00;
    border: 2px solid #ffffff;
}

button.ContextTab[aria-selected="false"] {
    background-color: darkgrey;
}

.ContextTabsIndicator {
    display: none;
}

.ContextTabsContainer {
    margin-top: 5px;
}
