@import "../../assets/styles/shared-styles.scss";

.app-wide-message {
    position: fixed;
    display: flex;
    width: 100%;
    min-height: calc(#{$header-height} + (#{$padding-top-bottom} * 2));
    top: 0;
    left: 0;
    right: 0;
    font-size: 0.8em;
    z-index: calc(#{$header-zIndex} + 2);
    padding: 5px 0;

    &--danger {
        color: #721c24;
        border: solid 1px #f5c6cb;
        background-color: #f8d7da;
    }

    &--success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    &__message,
    &__close {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__message {
        width: 85%;
        // margin-left: 15%;
        font-weight: bold;

        &__warning-icon,
        &__text {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__warning-icon {
            width: 15%;
        }

        &__text {
            width: 85%;
        }
    }

    &__close {
        cursor: pointer;
        width: 15%;
    }
}
