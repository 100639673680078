@import "src/assets/styles/shared-styles.scss";

label.add-media-button {
    cursor: pointer;
    width: 230px;
    padding: 10px;
    background-color: yellow;
    border: solid 2px grey;
    text-align: center;
    font-weight: bold;
    margin: 0 auto 15px;

    &--disabled {
        background-color: lightgray;
    }
}

.category-content {
    display: inline;
}

#context-media-upload {
    display: none;
}

.media-warning {
    position: fixed;
    display: flex;
    width: 100%;
    min-height: calc(#{$header-height} + (#{$padding-top-bottom} * 2));
    top: 0;
    left: 0;
    right: 0;
    z-index: calc(#{$header-zIndex} + 1);
    color: #721c24;
    background-color: #f8d7da;
    padding: 5px 0;
    border: solid 1px #f5c6cb;

    &__message,
    &__close {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__message {
        width: 85%;
        // margin-left: 15%;
        font-weight: bold;

        &__warning-icon,
        &__text {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__warning-icon {
            width: 15%;
        }

        &__text {
            width: 85%;
        }
    }

    &__close {
        cursor: pointer;
        width: 15%;
    }
}
